import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { io, Socket } from 'socket.io-client';

const SOCKET_URL = 'https://sr-server-1df31ed512f3.herokuapp.com';

// Define the context shape
const SocketContext = createContext({
  socket: null,
  isConnected: false,
  connectionStatus: 'disconnected', // 'connected', 'connecting', 'disconnected'
  subscribe: (pair) => {},
  unsubscribe: (pair) => {},
  lastError: null,
});

// Custom hook for using the socket context
export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

export const SocketProvider = ({ children }) => {
  // Socket instance ref
  const socketRef = useRef(null);
  
  // State
  const [isConnected, setIsConnected] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('disconnected');
  const [lastError, setLastError] = useState(null);
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 5;
  const reconnectInterval = 5000; // 5 seconds

  // Initialize socket connection
  useEffect(() => {
    const initializeSocket = () => {
      try {
        // Create socket instance
        socketRef.current = io(SOCKET_URL, {
          reconnection: true,
          reconnectionDelay: reconnectInterval,
          reconnectionAttempts: maxReconnectAttempts,
          withCredentials: true  // Important for CORS
        });

        // Connection handlers
        socketRef.current.on('connect', () => {
          console.log('Socket connected');
          setIsConnected(true);
          setConnectionStatus('connected');
          setLastError(null);
          reconnectAttempts.current = 0;
        });

        socketRef.current.on('disconnect', () => {
          console.log('Socket disconnected');
          setIsConnected(false);
          setConnectionStatus('disconnected');
        });

        socketRef.current.on('connect_error', (error) => {
          console.error('Connection error:', error);
          setLastError(error.message);
          setConnectionStatus('disconnected');
          
          reconnectAttempts.current += 1;
          if (reconnectAttempts.current >= maxReconnectAttempts) {
            console.log('Max reconnection attempts reached');
            socketRef.current.disconnect();
          }
        });

        // Status handlers
        socketRef.current.on('connection_status', (data) => {
          console.log('Connection status:', data);
        });

        socketRef.current.on('subscription_status', (data) => {
          console.log('Subscription status:', data);
        });

        socketRef.current.on('error', (error) => {
          console.error('Socket error:', error);
          setLastError(error.message);
        });

      } catch (error) {
        console.error('Socket initialization error:', error);
        setLastError(error.message);
      }
    };

    initializeSocket();

    // Cleanup on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  // Subscribe to a currency pair
  const subscribe = useCallback((pair) => {
    if (socketRef.current && isConnected) {
      console.log('Subscribing to:', pair);
      socketRef.current.emit('subscribe', { pair });
    } else {
      console.warn('Cannot subscribe: socket not connected');
    }
  }, [isConnected]);

  // Unsubscribe from a currency pair
  const unsubscribe = useCallback((pair) => {
    if (socketRef.current && isConnected) {
      console.log('Unsubscribing from:', pair);
      socketRef.current.emit('unsubscribe', { pair });
    }
  }, [isConnected]);

  // Expose the context value
  const value = {
    socket: socketRef.current,
    isConnected,
    connectionStatus,
    subscribe,
    unsubscribe,
    lastError,
  };

  return (
    <SocketContext.Provider value={value}>
      {children}
    </SocketContext.Provider>
  );
};