export const Logo = () => {
  const isMobile = window.innerWidth <= 768; // Check if the screen width is mobile-sized

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 400 100"
      style={{ width: '400px', height: 'auto' }} // Adjust width as needed
    >
      <text 
        x={isMobile ? "50%" : "0"} // Center horizontally on mobile
        y="60" 
        fontFamily="Courier New" 
        fontSize="50" 
        fontWeight="bold" 
        letterSpacing="4" 
        fill="black"
        textAnchor={isMobile ? "middle" : "unset"} // Center text-anchor on mobile
      >
        stablerates
      </text>
    </svg>
  );
};