import { createChart } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';

function Chart({ data, newPoints, onLoadMoreData, clearNewDataPoints, duration }) {
  const chartContainerRef = useRef();
  const chartInstanceRef = useRef();
  const lineSeriesRef = useRef();
  const oldestDataTimestampRef = useRef(null);
  const initialLoadCompleteRef = useRef(false);

  const BUFFER = duration === '7d' ? 1000000 : 70000;
  const loadThrottleRef = useRef(false);
  const [combinedData, setCombinedData] = useState([]);
  const visibleRangeRef = useRef(null);

  // Function to reset chart state
  const resetChart = () => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.remove();
      chartInstanceRef.current = null;
    }
    lineSeriesRef.current = null;
    oldestDataTimestampRef.current = null;
    initialLoadCompleteRef.current = false;
    visibleRangeRef.current = null;
    setCombinedData([]);
  };

  // Effect to handle chart creation and cleanup
  useEffect(() => {
    if (!chartContainerRef.current) return;

    const isMobile = window.innerWidth < 1024; // matches our lg: breakpoint


    // Create new chart instance
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        background: { 
          type: 'solid', 
          color: 'white'
        },
        padding: {
          left: isMobile ? 25 : 35,  // Less padding on mobile
          top: 5,    // Minimal padding top
          right: 5,  // Minimal padding right
          bottom: 5  // Minimal padding bottom
        },
        textColor: '#6B7280',
        fontSize: 8,  // This should now affect the font size
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif'
      },
      rightPriceScale: {
        borderVisible: false,
        scaleMargins: {
          top: 0.05,    // Reduce top margin
          bottom: 0.05  // Reduce bottom margin
        },
        entireTextOnly: true,  // Ensures price labels aren't cut off
        alignLabels: true,     // Aligns price labels to the right
        ticksVisible: false,    // Removes the small ticks on the axis
        // Mobile-specific settings
        ...(isMobile && {
          textColor: '#6B7280',
          tickMarkLength: 0, // Remove tick marks
          drawTicks: false,  // Don't draw ticks
          minTickMarkSpacing: 80, // Increase space between price labels
          ticksVisible: false,
          mode: 1 // Integer mode - show fewer decimal places
        })
      },
      grid: {
        vertLines: { 
          visible: !isMobile,
          style: 1,
          color: 'rgba(200, 200, 200, 0.7)' // Lighter grid lines
         }, // Hide on mobile
        horzLines: { 
          visible: !isMobile,
          style: 1,
          color: 'rgba(200, 200, 200, 0.7)' // Lighter grid lines
         }  // Hide on mobile
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderVisible: false,
        fixLeftEdge: true,     // Prevents scrolling past the left edge
        fixRightEdge: true,     // Prevents scrolling past the right edge
        ...(isMobile && {
          minBarSpacing: 0.05, // Compress on mobile to fit more data
          barSpacing: 4,  // Add explicit bar spacing
          rightOffset: 0,
          handleScroll: false,  // Disable scrolling on mobile
          handleScale: false,   // Disable zooming on mobile
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          
        })
      }
    });
    
    chartInstanceRef.current = chart;

    // Add area series instead of line series
    lineSeriesRef.current = chart.addAreaSeries({
      lineColor: 'rgba(41, 98, 255, 1)',
      topColor: 'rgba(41, 98, 255, 0.5)',
      bottomColor: 'rgba(41, 98, 255, 0.05)',
      lineWidth: 2,
      priceLineVisible: false,
      crosshairMarkerVisible: false,
      lastValueVisible: true,
      
    });

    chart.timeScale().fitContent();

    // Store visible range when it changes
    chart.timeScale().subscribeVisibleTimeRangeChange((newVisibleRange) => {
      if (!initialLoadCompleteRef.current || loadThrottleRef.current) return;
    
      if (newVisibleRange) {
        const rightEdgeTimestamp = newVisibleRange.to;
        const oldestDataTimestamp = oldestDataTimestampRef.current;
    
        if (oldestDataTimestamp && rightEdgeTimestamp <= oldestDataTimestamp + BUFFER) {
          loadThrottleRef.current = true;
          console.log("Threshold Reached - Loading More Data...");
          onLoadMoreData();
    
          setTimeout(() => {
            loadThrottleRef.current = false;
          }, 500);
        }
      }
    });
    

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
      if (visibleRangeRef.current) {
        chart.timeScale().setVisibleRange(visibleRangeRef.current);
      } else {
        chart.timeScale().fitContent();
      }
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      resetChart();
    };
  }, []);

  useEffect(() => {
    if (data.length > 0 && chartInstanceRef.current) {
      const isMobile = window.innerWidth < 1024;
      
      if (isMobile) {
        const now = Math.floor(Date.now() / 1000);
        const oneWeekAgo = now - (7 * 24 * 60 * 60);
        
        // Filter data to only show last week
        const weekData = data.filter(point => point.time >= oneWeekAgo);
        
        lineSeriesRef.current.setData(weekData);
        
        // Force the range on mobile
        chartInstanceRef.current.timeScale().setVisibleRange({
          from: oneWeekAgo,
          to: now
        });
      } else {
        // Desktop behavior
        lineSeriesRef.current.setData(data);
        
      }
    }
  }, [data]);


  // Effect to handle initial data load
  useEffect(() => {
    if (data.length > 0) {
      setCombinedData(data);
      if (lineSeriesRef.current) {
        lineSeriesRef.current.setData(data);
        oldestDataTimestampRef.current = data[0].time;
        initialLoadCompleteRef.current = true;

        const from = data[0].time;
        const to = data[data.length - 1].time;
        if (from && to && chartInstanceRef.current) {
          chartInstanceRef.current.timeScale().setVisibleRange({ from, to });
        }
      }
    }
  }, [data]);

  // Effect to handle new data points
  // Separate effect to handle historical data loading
  useEffect(() => {
    if (newPoints.length > 0 && lineSeriesRef.current) {
      // For historical data, add to start (older data)
      const updatedData = [...newPoints, ...combinedData];
      setCombinedData(updatedData);
      lineSeriesRef.current.setData(updatedData);
      clearNewDataPoints();
      oldestDataTimestampRef.current = updatedData[0].time;

      setTimeout(() => {
        loadThrottleRef.current = false;
      }, 500);
    }
  }, [newPoints, combinedData, clearNewDataPoints]);

  // New effect specifically for WebSocket updates
  useEffect(() => {
    if (!lineSeriesRef.current) return;

    // Function to handle new WebSocket data
    const handleWebSocketUpdate = (event) => {
      const wsData = event.detail;
      console.log('Chart received WebSocket data:', wsData);
    
      if (!wsData || !wsData.time || !wsData.value) {
        console.log('Invalid websocket data received');
        return;
      }
    
      // Find index of existing point with same timestamp
      const existingIndex = combinedData.findIndex(point => point.time === wsData.time);
      
      // Replace if exists, otherwise add new
      const updatedData = [...combinedData];
      if (existingIndex !== -1) {
        console.log('Replacing existing point:', wsData.time);
        updatedData[existingIndex] = wsData;
      } else {
        updatedData.push(wsData);
        updatedData.sort((a, b) => a.time - b.time);
      }
    
      setCombinedData(updatedData);
      lineSeriesRef.current.setData(updatedData);
    
      // Update view if needed
      if (chartInstanceRef.current && isViewingRecentData()) {
        chartInstanceRef.current.timeScale().scrollToPosition(0, true);
      }
    };
    // Subscribe to WebSocket updates
    window.addEventListener('websocket-price-update', handleWebSocketUpdate);

    return () => {
      window.removeEventListener('websocket-price-update', handleWebSocketUpdate);
    };
  }, [combinedData]);

  // Helper function to check if user is viewing recent data
  const isViewingRecentData = () => {
    if (!chartInstanceRef.current || !combinedData.length) return false;
    
    const visibleRange = chartInstanceRef.current.timeScale().getVisibleRange();
    if (!visibleRange) return false;

    const lastDataTime = combinedData[combinedData.length - 1].time;
    return visibleRange.to >= lastDataTime - 300; // Within 5 minutes of latest
  };

  return <div ref={chartContainerRef} style={{ position: 'relative', width: '100%', height: '100%' }} />;
}

export default Chart;