// Create a new file Logo.js in your src/components folder
export const Logo = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 400 100"
    className={className}
  >
    <text 
      x="50" 
      y="60" 
      fontFamily="Courier New" 
      fontSize="40" 
      fontWeight="bold" 
      letterSpacing="4" 
      fill="black"
    >
      stablerates
    </text>
  </svg>
);