import React from 'react';
import './Layout.css';
import { Logo } from './components/Logo.js';
import { Card } from './components/ui/card';
import { TrendingDown, TrendingUp, Clock, ChevronDown, Wifi, WifiOff } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// Currency name mapping
export const currencyInfo = {
  'NGN': { name: 'Nigerian Naira', symbol: '₦', precedes: true, flag: '🇳🇬' },
  'ETB': { name: 'Ethiopian Birr', symbol: 'Br', precedes: false, flag: '🇪🇹' },
  'XAF': { name: 'Central African CFA', symbol: 'CFA', precedes: false, flag: '🇨🇫' },
  'BOB': { name: 'Bolivian Boliviano', symbol: 'BOB', precedes: false, flag: '🇧🇴' },
  'VES': { name: 'Venezuelan Bolívar', symbol: 'VES', precedes: false, flag: '🇻🇪' },
  'DZD': { name: 'Algerian Dinar', symbol: 'DA', precedes: false, flag: '🇩🇿' },
  'TND': { name: 'Tunisian Dinar', symbol: 'DT', precedes: false, flag: '🇹🇳' },
  'BDT': { name: 'Bangladeshi Taka', symbol: '৳', precedes: true, flag: '🇧🇩' },
  'ARS': { name: 'Argentine Peso', symbol: 'AR$', precedes: true, flag: '🇦🇷' }
};

export const getCurrencyInfo = (pair) => {
  const currencyCode = pair.slice(-3);
  return currencyInfo[currencyCode] || { 
    name: currencyCode, 
    symbol: currencyCode, 
    precedes: false,
    flag: '🌐'
  };
};

function TopBar({ connectionStatus }) {

  const navigate = useNavigate();

  const getConnectionStyles = () => {
    switch (connectionStatus) {
      case 'connected':
        return 'bg-green-500';
      case 'connecting':
        return 'bg-yellow-500';
      case 'disconnected':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  return (
    <div className="col-span-3 flex items-center h-[42px] py-0 px-3 bg-white border-b border-gray-100">
      {/* Left section: Logo */}
      <div 
        className="flex w-[120px] lg:w-[120px] justify-start cursor-pointer hover:opacity-80 transition-opacity"
        onClick={() => navigate('/')}
      >
        <Logo className="h-8 w-auto"/>
      </div>

      {/* Spacer to push the right section */}
      <div className="flex-1"></div>

      {/* Right section: Live/Connection status */}
      <div className="flex items-center px-3">
        <div className="flex items-center gap-2 px-2 py-0 rounded-full bg-gray-50 text-tiny text-gray-600">
          <span className="capitalize">
            {connectionStatus === 'connected' ? 'Live' : connectionStatus}
          </span>
          <div className={`w-2 h-2 rounded-full ${getConnectionStyles()} ${
            connectionStatus === 'connected' ? 'animate-pulse' : ''
          }`} />
        </div>
      </div>
    </div>
  );
}


function LeftSidebar() {
  return (
    <div className="border-r border-gray-200">
      {/* Add sidebar content here */}
    </div>
  );
}

function TimeframeBar() {
  return (
    <div className="h-10 flex items-center justify-center px-6 text-xs text-gray-500">
      7d view • Updates every 5m
    </div>
  );
}


function MetricsPanel({ 
  classname,
  selectedPair, 
  pairs, 
  onPairChange,
  className, 
  price = '---', 
  priceChange = '---',
  priceChangeValue = '---',
  fiatPrice = '---',
  priceDiff = '---',
  priceDiffPct = '---',
  lastUpdate = '---',
  performance = {
    week: '---',
    weekValue: '---',
    month: '---',
    monthValue: '---',
    quarter: '---',
    quarterValue: '---'
  }
}) {
  const getCurrencyInfo = (pair) => {
    const currencyCode = pair.slice(-3);
    return currencyInfo[currencyCode] || { 
      name: currencyCode, 
      symbol: currencyCode, 
      precedes: false,
      flag: '🌐'
    };
  };

  const formatCurrencyValue = (value, pair) => {
    // Early return for undefined, null, or '---'
    if (!value || value === '---' || value === '') return '---';

    const info = getCurrencyInfo(pair);
    
    // Convert value to string if it isn't already
    const valueStr = value.toString();
    
    // Extract sign if it exists
    const hasSign = valueStr.startsWith('+') || valueStr.startsWith('-');
    const sign = hasSign ? valueStr[0] : '';
    const number = hasSign ? valueStr.slice(1) : valueStr;

    try {
      // Format number with thousand separators
      const formattedNumber = parseFloat(number).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      return info.precedes 
        ? `${sign}${info.symbol}${formattedNumber}`
        : `${sign}${formattedNumber} ${info.symbol}`;
    } catch (error) {
      console.error('Error formatting currency value:', error);
      return '---';
    }
  };

  const getDynamicFontSize = (value, symbol, isStreetratePremium = false, percentageValue = '') => {
    // For Streetrate Premium, include the percentage in length calculation
    const totalLength = isStreetratePremium
      ? `${value} ${symbol} (${percentageValue})`.length
      : `${value} ${symbol}`.length;
        
    if (totalLength > 14) {
      return 'text-sm lg:text-small'; // Smaller text for longer content
    }
    return 'text-base lg:text-base'; // Default size
  };

  const currentCurrency = getCurrencyInfo(selectedPair);
  

  return (
  <div className={`bg-gray-50 ${className}`}>
    <div className="p-3 flex flex-col gap-3 h-full">
      {/* Desktop Currency Selector - Hidden on mobile */}
      <Card className="bg-white shrink-0 hidden lg:block">
        <div className="p-2">
          <div 
            className="flex text-small items-center justify-between gap-3 cursor-pointer" 
            onClick={() => document.getElementById('desktop-pair-select').click()}
          >
            <div className="text-lg">{currentCurrency.flag}</div>
            <div className="flex-1">
              <select 
                id="desktop-pair-select"
                value={selectedPair} 
                onChange={onPairChange}
                className="w-full font-medium bg-transparent appearance-none cursor-pointer focus:outline-none text-l"
              >
                {pairs.map((pair) => (
                  <option key={pair} value={pair} className="text-gray-900 bg-white">
                    {pair}
                  </option>
                ))}
              </select>
              <div className="text-tiny text-gray-500">{currentCurrency.name}</div>
            </div>
            <div className="pr-2"> {/* Added padding */}
              <ChevronDown className="w-5 h-5 text-gray-400" />
            </div>
          </div>
        </div>
      </Card>
      
      
      {/* Combined Price and Performance Card */}
      <Card className="bg-white flex-1">
        <div className="h-full flex flex-col divide-y divide-gray-100">
          {/* Price Section */}
          <div className="p-3 lg:p-4">
            <div className="grid grid-cols-2 gap-2.5">
              <div className="col-span-2">
                <div className="text-xl lg:text-xl font-medium">
                  {formatCurrencyValue(price, selectedPair)}
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <span className="text-xs text-gray-500">24h:</span>
                  <div className="text-xs flex items-center gap-1">
                    {parseFloat(priceChange) >= 0 ? (
                      <TrendingUp className="w-4 h-4 text-red-500" />
                    ) : (
                      <TrendingDown className="w-4 h-4 text-green-500" />
                    )}
                    <span className={parseFloat(priceChange) >= 0 ? 'text-red-500' : 'text-green-500'}>
                      {formatCurrencyValue(priceChangeValue, selectedPair)} ({priceChange}%)
                    </span>
                  </div>
                </div>
              </div>
              
              <div className="p-3 bg-gray-50 rounded-lg ">
                <div className="text-xs text-gray-500">Official USD Rate</div>
                <div className={`${getDynamicFontSize(fiatPrice, currentCurrency.symbol)} font-medium mt-1`}>
                  {formatCurrencyValue(fiatPrice, selectedPair)}
                </div>
              </div>
              
              <div className={`p-3 rounded-lg ${parseFloat(priceDiffPct) > 0 ? 'bg-red-50' : 'bg-green-50'}`}>
                <div className="text-xs text-gray-500">Stablerate Premium</div>
                <div className={`${getDynamicFontSize(priceDiff, currentCurrency.symbol, true, priceDiffPct)} font-medium mt-1 ${parseFloat(priceDiffPct) > 0 ? 'text-red-500' : 'text-green-500'}`}>
                  {formatCurrencyValue(priceDiff, selectedPair)} ({priceDiffPct}%)
                </div>
              </div>

              <div className="col-span-2 flex items-center gap-2 text-gray-500 text-tiny">
                <Clock className="w-3 h-3" />
                <span>Last update: {lastUpdate}</span>
              </div>
            </div>
          </div>

          {/* Performance Section */}
          <div className="hidden lg:block p-4 flex-1">
            <h3 className="text-small font-medium text-gray-900 mb-4">Performance</h3>
            <div className="grid grid-cols-3 gap-3">
              <div className="bg-gray-50 p-3 rounded-lg text-center">
                <div className="text-xs text-gray-500 mb-1">1W</div>
                <div className={`text-xs font-medium ${parseFloat(performance.week) >= 0 ? 'text-red-500' : 'text-green-500'}`}>
                  {formatCurrencyValue(performance.weekValue, selectedPair)}
                  <br />
                  {performance.week}%
                </div>
              </div>
              <div className="bg-gray-50 p-3 rounded-lg text-center">
                <div className="text-xs text-gray-500 mb-1">1M</div>
                <div className={`text-xs font-medium ${parseFloat(performance.month) >= 0 ? 'text-red-500' : 'text-green-500'}`}>
                  {formatCurrencyValue(performance.monthValue, selectedPair)}
                  <br />
                  {performance.month}%
                </div>
              </div>
              <div className="bg-gray-50 p-3 rounded-lg text-center">
                <div className="text-xs text-gray-500 mb-1">3M</div>
                <div className="text-xs font-medium">
                  {formatCurrencyValue(performance.quarterValue, selectedPair)}
                  <br />
                  {performance.quarter}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
);
}

export { TopBar, TimeframeBar, MetricsPanel }; // Remove LeftSidebar


