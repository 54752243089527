import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import Chart from '../Chart';
import { TopBar, TimeframeBar, MetricsPanel, getCurrencyInfo } from '../Layout';
import { ChevronDown } from 'lucide-react';
import { Card } from './ui/card';
import { useSocket } from './SocketProvider';


function Dashboard() {
    // Get socket context once at component level
    const { socket, subscribe, unsubscribe, isConnected, connectionStatus } = useSocket();


    const [pairs, setPairs] = useState([]);
    const [selectedPair, setSelectedPair] = useState(() => {
    return localStorage.getItem('selectedPair') || 'USDTNGN';
    });
    const [chartData, setChartData] = useState([]);
    const [page, setPage] = useState(1);
    const pageRef = useRef(1);
    const [totalPages, setTotalPages] = useState(1);
    const loadingRef = useRef(false);
    const [loading, setLoading] = useState(false);
    const totalPagesRef = useRef(totalPages);
    const isLoadMoreTriggered = useRef(false);
    const pairTimestamps = useRef({});
    const [newDataPoints, setNewDataPoints] = useState([]);
    const debounceTimeoutRef = useRef(null);
    const DEBOUNCE_DELAY = 200;
    const duration = '7d';



    // Add performance data state
    const [performanceData, setPerformanceData] = useState({
    currentPrice: '---',
    priceChange24h: '---',
    priceChange24hValue: '---',
    fiatRate: '---',
    fiatDiff: '---',
    fiatDiffPct: '---',
    lastUpdate: '---',
    performance: {
        week: '---',
        weekValue: '---',
        month: '---',
        monthValue: '---',
        quarter: '---',
        quarterValue: '---'
    }
    });

    // Effect for socket subscription
    useEffect(() => {
    if (isConnected && selectedPair) {
        // Unsubscribe from any previous pair
        pairs.forEach(pair => {
        if (pair !== selectedPair) {
            unsubscribe(pair);
        }
        });
        
        // Subscribe to new pair
        subscribe(selectedPair);
    }
    }, [isConnected, selectedPair, subscribe, unsubscribe, pairs]);

    useEffect(() => {
    if (!isConnected || !socket) return;
    
    function handlePriceUpdate(data) {
        if (data.pair === selectedPair) {
        if (data.data.chart_data) {
            console.log('WebSocket received:', data.data.chart_data);
            const newPoint = {
            time: data.data.chart_data.time,
            value: data.data.chart_data.value
            };
            console.log('Formatted point:', newPoint);
            const event = new CustomEvent('websocket-price-update', { 
            detail: newPoint 
            });
            window.dispatchEvent(event);
        }
    
        // Update performance metrics with all the new fields
        if (data.data.metrics) {
            const metrics = data.data.metrics;
            setPerformanceData(prev => ({
            ...prev,
            currentPrice: metrics.current_price.toFixed(2),
            priceChange24h: metrics.price_change_24h_pct?.toFixed(2) || '---',
            priceChange24hValue: metrics.price_change_24h?.toFixed(2) || '---',
            fiatRate: metrics.fiat_rate?.toFixed(2) || '---',
            fiatDiff: metrics.fiat_diff?.toFixed(2) || '---',
            fiatDiffPct: metrics.fiat_diff_pct?.toFixed(2) || '---',
            lastUpdate: new Date(metrics.last_update).toLocaleString(),
            performance: {
                week: metrics.week_change_pct?.toFixed(2) || '---',
                weekValue: metrics.week_change?.toFixed(2) || '---',
                month: metrics.month_change_pct?.toFixed(2) || '---',
                monthValue: metrics.month_change?.toFixed(2) || '---',
                quarter: metrics.quarter_change_pct?.toFixed(2) || '---',
                quarterValue: metrics.quarter_change?.toFixed(2) || '---'
            }
            }));
        }
        }
    }

    socket.on('price_update', handlePriceUpdate);

    return () => {
        socket.off('price_update', handlePriceUpdate);
    };
    }, [isConnected, selectedPair, socket]);

    useEffect(() => {
    fetch('https://sr-server-1df31ed512f3.herokuapp.com/pairs')
        .then((response) => response.json())
        .then((data) => {
        if (data && Array.isArray(data.pairs)) {
            setPairs(data.pairs);
            if (!data.pairs.includes(localStorage.getItem('selectedPair'))) {
            setSelectedPair('USDTNGN');
            localStorage.setItem('selectedPair', 'USDTNGN');
            }
        } else {
            console.error("Unexpected response format:", data);
            setPairs([]);
        }
        })
        .catch((error) => {
        console.error('Error fetching pairs:', error);
        setPairs([]);
        });
    }, []);

    useEffect(() => {
    totalPagesRef.current = totalPages;
    }, [totalPages]);

    useEffect(() => {
    if (!pairTimestamps.current[selectedPair]) {
        pairTimestamps.current[selectedPair] = new Set();
    }

    fetch(`https://sr-server-1df31ed512f3.herokuapp.com/total_pages?limit=100&pair=${selectedPair}`)
        .then((response) => response.json())
        .then((data) => {
        const fetchedTotalPages = data.total_pages || 1;
        setTotalPages(fetchedTotalPages);
        setPage(1);
        loadData(1, true);
        })
        .catch((error) => console.error("Error fetching total pages:", error));
    }, [selectedPair]);

    useEffect(() => {
    if (!pairTimestamps.current[selectedPair]) {
        pairTimestamps.current[selectedPair] = new Set();
    }

    // Fetch total pages and metrics
    Promise.all([
        fetch(`https://sr-server-1df31ed512f3.herokuapp.com/total_pages?limit=100&pair=${selectedPair}`),
        fetchMetrics(selectedPair)
    ]).then(([totalPagesResponse]) => {
        totalPagesResponse.json().then(data => {
        const fetchedTotalPages = data.total_pages || 1;
        setTotalPages(fetchedTotalPages);
        setPage(1);
        loadData(1, true);
        });
    }).catch(error => console.error("Error fetching data:", error));
    }, [selectedPair]);

    const loadData = async (pageNumber, isInitialLoad = false) => {
    const requestPair = selectedPair;
    console.log(`[${Date.now()}] loadData called with selectedPair=${requestPair}, page=${pageNumber}, duration=${duration}`);

    if (loadingRef.current || pageNumber < 1 || pageNumber > totalPagesRef.current) {
        console.log("Skipping data load due to loadingRef state or invalid page number");
        return;
    }

    setLoading(true);
    loadingRef.current = true;

    try {
        const response = await fetch(`https://sr-server-1df31ed512f3.herokuapp.com/data?pair=${requestPair}&duration=${duration}&page=${pageNumber}`);
        const data = await response.json();

        if (requestPair === selectedPair) {
        const transformedData = data.data.map((item) => ({
            time: new Date(item.interval_start).getTime() / 1000,
            value: item.midpoint,
        }));

        const currentPairTimestamps = pairTimestamps.current[requestPair];

        if (isInitialLoad) {
            currentPairTimestamps.clear();
            transformedData.forEach(item => currentPairTimestamps.add(item.time));
            setChartData(transformedData);
            
            // Update performance data with the latest value
            if (transformedData.length > 0) {
            const latestData = transformedData[transformedData.length - 1];
            setPerformanceData(prev => ({
                ...prev,
                currentPrice: latestData.value.toFixed(2),
                lastUpdate: new Date(latestData.time * 1000).toLocaleString(),
            }));
            }
        } else {
            const newUnique = transformedData.filter(item => !currentPairTimestamps.has(item.time));
            if (newUnique.length > 0) {
            newUnique.forEach(item => currentPairTimestamps.add(item.time));
            setNewDataPoints(newUnique);
            }
        }

        setPage(pageNumber);
        pageRef.current = pageNumber;
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    } finally {
        loadingRef.current = false;
        setLoading(false);
        isLoadMoreTriggered.current = false;
    }
    };

    const loadMoreData = () => {
    if (loadingRef.current || isLoadMoreTriggered.current) {
        return;
    }

    console.log("🚀 loadMoreData triggered!");
    isLoadMoreTriggered.current = true;

    if (pageRef.current < totalPagesRef.current) {
        const nextPage = pageRef.current + 1;
        pageRef.current = nextPage;
        loadData(nextPage);
    } else {
        console.log("No more pages to load.");
        isLoadMoreTriggered.current = false;
    }
    };

    const handlePairChange = (event) => {
    const newPair = event.target.value;
    
    if (!pairTimestamps.current[newPair]) {
        pairTimestamps.current[newPair] = new Set();
    }
    
    setSelectedPair(newPair);
    localStorage.setItem('selectedPair', newPair);
    
    setChartData([]);
    setNewDataPoints([]);
    pageRef.current = 1;
    setPage(1);
    loadingRef.current = false;
    isLoadMoreTriggered.current = false;
    };

    const fetchMetrics = async (pair) => {
    try {
        const timeframes = ['24h', '1w', '1m', '3m'];
        const params = new URLSearchParams();
        params.append('pair', pair);
        timeframes.forEach(t => params.append('timeframe[]', t));
        
        const response = await fetch(`https://sr-server-1df31ed512f3.herokuapp.com/metrics?${params}`);
        const data = await response.json();
        
        if (response.ok) {
        setPerformanceData(prev => ({
            ...prev,
            currentPrice: data.current_price.toFixed(2),
            priceChange24h: data['24h']?.price_change_pct.toFixed(2) || '---',
            priceChange24hValue: data['24h']?.price_change.toFixed(2) || '---',
            fiatRate: data.fiat_rate?.toFixed(2) || '---',        
            fiatDiff: data.fiat_diff?.toFixed(2) || '---',        
            fiatDiffPct: data.fiat_diff_pct?.toFixed(2) || '---', 
            lastUpdate: new Date(data.last_update).toLocaleString(),
            performance: {
            week: data['1w']?.price_change_pct.toFixed(2) || '---',
            weekValue: data['1w']?.price_change.toFixed(2) || '---',
            month: data['1m']?.price_change_pct.toFixed(2) || '---',
            monthValue: data['1m']?.price_change.toFixed(2) || '---',
            quarter: data['3m']?.price_change_pct.toFixed(2) || '---',
            quarterValue: data['3m']?.price_change.toFixed(2) || '---'
            }
        }));
        }
    } catch (error) {
        console.error("Error fetching metrics:", error);
    }
    };


    return (
    <div className="flex flex-col lg:grid lg:grid-cols-[1fr_350px] lg:grid-rows-[42px_1fr] h-screen bg-white">
        <TopBar connectionStatus={connectionStatus} />
        
        {/* Mobile Layout - Hidden on desktop */}
        <main className="flex-1 flex flex-col bg-gray-50 pt-4 lg:hidden">
        {/* Currency Selector */}
        <div className="px-4 w-full mb-4 lg:hidden">
            <Card className="w-full bg-white">
            <div className="p-2">
                <div 
                className="flex items-center justify-between gap-3 cursor-pointer" 
                onClick={() => document.querySelector('select').click()}
                >
                <div className="text-2xl">{getCurrencyInfo(selectedPair).flag}</div>
                <div className="flex-1">
                    <select 
                    value={selectedPair} 
                    onChange={handlePairChange}
                    className="w-full font-medium bg-transparent appearance-none cursor-pointer focus:outline-none"
                    >
                    {pairs.map((pair) => (
                        <option key={pair} value={pair}>{pair}</option>
                    ))}
                    </select>
                    <div className="text-sm text-gray-500">{getCurrencyInfo(selectedPair).name}</div>
                </div>
                <div className="pr-2"> {/* Added padding */}
                    <ChevronDown className="w-5 h-5 text-gray-400" />
                </div>
                </div>
            </div>
            </Card>
        </div>

        {/* Chart Section */}
        <div className="px-4 w-full">
            <Card className="w-full bg-white">
            <div className="h-[52vh] lg:h-full flex flex-col"> 
                <div className="flex-1 relative p-4">
                <Chart 
                    key={selectedPair}
                    data={chartData} 
                    newPoints={newDataPoints} 
                    onLoadMoreData={loadMoreData} 
                    clearNewDataPoints={() => setNewDataPoints([])}
                    duration={duration}
                />
                </div>
                <TimeframeBar />
            </div>
            </Card>
        </div>

        {/* Metrics Panel */}
        <div className="w-full">
            <MetricsPanel   
            selectedPair={selectedPair}
            pairs={pairs}
            onPairChange={handlePairChange}
            price={performanceData.currentPrice}
            priceChange={performanceData.priceChange24h}
            priceChangeValue={performanceData.priceChange24hValue}
            fiatPrice={performanceData.fiatRate}
            priceDiff={performanceData.fiatDiff}
            priceDiffPct={performanceData.fiatDiffPct}
            lastUpdate={performanceData.lastUpdate}
            performance={performanceData.performance}
            isConnected={isConnected}
            className="w-full"
            />
        </div>
        </main>

        {/* Desktop Layout - Hidden on mobile */}
        <div className="hidden lg:flex lg:flex-col lg:gap-4 lg:p-3 bg-gray-50 h-[calc(100vh-55px)]">
        <Card className="flex-1">
            <div className="h-full flex flex-col">
            <div className="flex-1 relative p-4">
                <Chart 
                key={selectedPair}
                data={chartData} 
                newPoints={newDataPoints} 
                onLoadMoreData={loadMoreData} 
                clearNewDataPoints={() => setNewDataPoints([])}
                duration={duration}
                />
            </div>
            <TimeframeBar />
            </div>
        </Card>
        </div>

        {/* Desktop Metrics Panel */}
        <div className="hidden lg:block h-[calc(100vh-58px)]">
        <MetricsPanel   
            selectedPair={selectedPair}
            pairs={pairs}
            onPairChange={handlePairChange}
            price={performanceData.currentPrice}
            priceChange={performanceData.priceChange24h}
            priceChangeValue={performanceData.priceChange24hValue}
            fiatPrice={performanceData.fiatRate}
            priceDiff={performanceData.fiatDiff}
            priceDiffPct={performanceData.fiatDiffPct}
            lastUpdate={performanceData.lastUpdate}
            performance={performanceData.performance}
            isConnected={isConnected}
            className="h-full" // Pass height to component
        />
        </div>
    </div>
    );
    
}
export default Dashboard;