import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SocketProvider } from './components/SocketProvider';
import Dashboard from './components/Dashboard';
import HomePage from './components/HomePage';
import GoogleAnalytics from './components/GoogleAnalytics'; // Add this

function App() {
  return (
    <Router>
      <GoogleAnalytics /> {/* Add this line */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/app" element={
          <SocketProvider>
            <Dashboard />
          </SocketProvider>
        } />
      </Routes>
    </Router>
  );
}

export default App;