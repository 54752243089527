import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Create script elements
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-87NHYTJZWB';

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-87NHYTJZWB');
    `;

    // Add scripts to head
    document.head.appendChild(gtagScript);
    document.head.appendChild(inlineScript);

    // Cleanup on unmount
    return () => {
      document.head.removeChild(gtagScript);
      document.head.removeChild(inlineScript);
    };
  }, []);

  return null;
};

export default GoogleAnalytics;