import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from './assets/Logo.js';
import config from '../config';  // Import config



const HomePage = () => {
  const navigate = useNavigate();
  const [rateData, setRateData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch initial pairs and their data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const pairsResponse = await fetch(`https://sr-server-1df31ed512f3.herokuapp.com//pairs`);
        const pairsData = await pairsResponse.json();
        
        if (pairsData && Array.isArray(pairsData.pairs)) {
          const metricsPromises = pairsData.pairs.map(async (pair) => {
            const timeframes = ['24h', '1w', '1m', '3m'];
            const params = new URLSearchParams();
            params.append('pair', pair);
            timeframes.forEach(t => params.append('timeframe[]', t));
            
            const response = await fetch(`https://sr-server-1df31ed512f3.herokuapp.com//metrics?${params}`);
            const data = await response.json();
            
            return {
              pair: pair.slice(-3),
              flag: getCurrencyFlag(pair.slice(-3)),
              name: getCurrencyName(pair.slice(-3)),
              usdtRate: data.current_price?.toFixed(2) || '---',
              officialRate: data.fiat_rate?.toFixed(2) || '---',
              premium: data.fiat_diff_pct ? `${data.fiat_diff_pct > 0 ? '+' : ''}${data.fiat_diff_pct.toFixed(2)}%` : '---'
            };
          });

          const results = await Promise.all(metricsPromises);
          setRateData(results);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getCurrencyName = (code) => {
    const names = {
      'NGN': 'Nigerian Naira',
      'ETB': 'Ethiopian Birr',
      'XAF': 'CFA Franc',
      'BOB': 'Bolivian Boliviano',
      'VES': 'Venezuelan Bolívar',
      'DZD': 'Algerian Dinar',
      'TND': 'Tunisian Dinar',
      'BDT': 'Bangladeshi Taka',
      'ARS': 'Argentine Peso'

    };
    return names[code] || code;
  };

  const getCurrencyFlag = (code) => {
    const flags = {
      'NGN': '🇳🇬',
      'ETB': '🇪🇹',
      'XAF': '🇨🇫',
      'BOB': '🇧🇴',
      'VES': '🇻🇪',
      'DZD': '🇩🇿',
      'TND': '🇹🇳',
      'BDT':'🇧🇩',
      'ARS':'🇦🇷'
    };
    return flags[code] || '🌐';
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-8">
      <div className="w-full max-w-7xl flex flex-col lg:flex-row items-center lg:justify-between">
        {/* Left section with logo and description */}
        <div className="w-full px- lg:w-[500px] flex flex-col items-center lg:items-start self-center lg:pr-6 text-sm">
            <div className="flex justify-center w-full lg:justify-start lg:w-[250px]">
                <Logo />
            </div>
            <p className="font-dark text-sm leading-relaxed text-gray-600 text-center lg:text-left mb-8">
            Get <strong> real time, true market rates for USD stablecoins</strong> in emerging markets. 
            Track the spread between stablecoin rates and official USD rates.
            </p>
            <button 
            onClick={() => navigate('/app')} 
            className="border border-gray-400 px-8 py-3 text-gray-900 hover:bg-gray-100 transition-colors font-medium"
            >
            Open App
            </button>
        </div>

        {/* Right section with rates table - hidden on mobile */}
        <div className="hidden lg:block lg:w-[580px] lg:ml-16">
          <div className="bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="overflow-x-auto">
              {loading ? (
                <div className="p-8 text-center text-gray-500">Loading rates...</div>
              ) : (
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="border-b border-gray-10 bg-gray-50">
                      <th className="py-2 px-6 text-left font-medium text-gray-500 uppercase tracking-wider text-tiny">Currency</th>
                      <th className="py-2 px-6 text-right font-medium text-gray-500 uppercase tracking-wider text-tiny">USDT Rate</th>
                      <th className="py-2 px-6 text-right font-medium text-gray-500 uppercase tracking-wider text-tiny">Official Rate</th>
                      <th className="py-2 px-6 text-right font-medium text-gray-500 uppercase tracking-wider text-tiny">Premium</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {rateData.map((rate) => (
                      <tr key={rate.pair} className="hover:bg-gray-50">
                        <td className="py-3 px-6">
                          <div className="flex items-center gap-3">
                            <span className="text-xs">{rate.flag}</span>
                            <div>
                              <div className="text-small font-medium">{rate.pair}</div>
                              <div className="text-tiny text-gray-500">{rate.name}</div>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 px-6 text-xs text-right font-medium">{rate.usdtRate}</td>
                        <td className="py-4 px-6 text-xs text-right text-gray-600">{rate.officialRate}</td>
                        <td className="py-4 px-6 text-xs text-right font-medium text-emerald-600">{rate.premium}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;